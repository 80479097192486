import React, { useEffect, useState, useMemo } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import SlideHeaderContentCta from "../elements/slide-header-content-cta";
import ComparatoreCardMono from "../elements/card-comparatore-mono";
import ConfigurazioneCard from "../elements/card-configurazione";
import withPreview from "../../utility/with-preview";
import { dataLayerPush } from "../../utility/dataLayerUtils";
import Cookies from "js-cookie";
import { Link } from "../link";
import classNames from "classnames";

const LandingHeaderComparatore = ({ data }) => {
  const {
    aboveCardTitle,
    // cta,
    ctaNew,
    imageDesktop,
    imageTablet,
    imageMobile,
    lowerBand,
    mainTitle,
    note,
    offertaTitle,
    stickyfooterText,
    commodity,
  } = data;

  const [chosenProd, setChosenProd] = useState(0);
  const [selectActive, setSelectActive] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedCard, setSelectedCard] = useState(0);

  const salable = useMemo(() => {
    try {
      let data = offertaTitle[chosenProd]?.content?.prodottoCRM?.dataFineValidita;
      // console.log("data", data);
      let timestampFineValidita = Date.parse(
        data.substring(3, 5) + "/" + data.substring(0, 2) + "/" + data.substring(6, 10)
      );
      let dataFineValidita = new Date(timestampFineValidita);
      const incremento = 23 * 60 * 60 * 1000 + 59 * 60 * 1000;
      dataFineValidita.setTime(dataFineValidita.getTime() + incremento);
      if (isNaN(dataFineValidita)) {
        //console.log('dataFineValidita...invalid');
        return false;
      }
      if (new Date() > dataFineValidita) {
        //console.log('expired');
        return false;
      } else {
        //console.log('still valid');
        return true;
      }
    } catch (error) {
      //console.log('error',error);
      return false;
    }
  }, [chosenProd, offertaTitle]);

  const headerImg = imageDesktop?.node
    ? withArtDirection(
        getImage(imageDesktop?.node),
        [
          imageMobile?.node && {
            media: "(max-width: 768px)",
            image: getImage(imageMobile.node),
          },
          imageTablet?.node && {
            media: "(max-width: 992px)",
            image: getImage(imageTablet.node),
          },
        ].filter(Boolean)
      )
    : null;

  const startFlusso = (productName, positionHeader = true) => {
    //console.log("Starting flusso", productName);
    const fluxEvent = new CustomEvent("start-flusso-switch-in", {
      detail: {
        productName,
      },
    });
    document.dispatchEvent(fluxEvent);
    dataLayerPush({
      event: "CTA - RISPARMIA - ORA",
      eventCategory: "Landing Page",
      eventAction: "Energia 3.0 Light",
      eventLabel: `${positionHeader ? "CTA_Header" : "CTA_Footer"} - ${
        chosenProd === 0 ? "12 Mesi" : "24 Mesi"
      }`,
    });
    return;
  };

  const cortesia = (productName, positionHeader = true) => {
    navigate(`/servizio-non-disponibile/`);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ctaAttivaOra = ctaNew.filter((item) => item.value === "ATTIVA ORA");
  // console.log("ctaAttivaOra", ctaAttivaOra);
  const ctaSimualtore = ctaNew.filter(
    (item) => item.value === "SIMULATORE BOLLETTA" || item.value === "FAI UNA SIMULAZIONE"
  );
  // console.log("ctaSimualtore", ctaSimualtore);
  const ctaChiamaOra = ctaNew.filter((item) => item.value === "CHIAMA ORA");
  // console.log("ctaChiamaOra", ctaChiamaOra);
  if (ctaAttivaOra.length === 1)
    ctaAttivaOra[0].content.linkCtaNew.value = `javascript:genericAttivaOnline('${offertaTitle?.[chosenProd]?.content?.offertaCtaLink?.value}')`;
  return (
    <section className="section landing-header-comparatore">
      <div className="landing-header-comparatore__top position-relative">
        <SlideHeaderContentCta
          headerImg={headerImg}
          headerRatio={imageMobile?.node?.probeInfo?.aspectRatio}
          title={mainTitle?.value}
          wrapped={true}
          flex={true}
          description={mainTitle?.content?.mainDescription?.value}
          productStars={{
            show: mainTitle?.content?.showProductStars?.value,
            prodId: mainTitle?.content?.showProductStars?.content?.dataProductId?.value,
          }}
          //Setto per lo SwitchIn sempre la CTA 1
          ctaList={ctaNew.map((item, index) =>
            item.value == "ATTIVA ORA"
              ? {
                  ...item,
                  content: {
                    ...item?.content,
                    linkCtaNew: {
                      value: `javascript:genericAttivaOnline('${offertaTitle?.[chosenProd]?.content?.offertaCtaLink?.value}')`,
                    },
                  },
                }
              : item
          )}
          countdown={mainTitle?.content?.cdownDate}
          ctaFlussoAction={salable ? startFlusso : cortesia}
          //  ctaList={ctaNew}
          isConfigurazione={!offertaTitle[0]?.content?.isConfigurabile?.value}
          ctaAttivaOra={ctaAttivaOra}
          ctaChiamaOra={ctaChiamaOra}
          ctaSimulatore={ctaSimualtore}
        />
      </div>

      {!offertaTitle[0]?.content?.isConfigurabile?.value && (
        <div className="landing-header-comparatore__card-title d-xl-none text-center pb-5 pt-md-5">
          {aboveCardTitle?.value}
        </div>
      )}

      {/*Se è un test offerta configurabile uso elemento card specifico */}
      {offertaTitle[0]?.content?.isConfigurabile?.value ? (
        <ConfigurazioneCard
          products={offertaTitle}
          chosenProd={chosenProd}
          setChosenProd={setChosenProd}
          selectActive={selectActive}
          setSelectActive={setSelectActive}
          commodity={commodity}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          ctaFlussoAction={salable ? startFlusso : cortesia}
          offertaTitle={offertaTitle}
          ctaList={ctaNew}
        />
      ) : (
        <ComparatoreCardMono
          products={offertaTitle}
          chosenProd={chosenProd}
          setChosenProd={setChosenProd}
          selectActive={selectActive}
          setSelectActive={setSelectActive}
          commodity={commodity}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      )}

      {(lowerBand?.content?.lowerText?.value ||
        lowerBand?.content?.imageSx?.node ||
        lowerBand?.content?.imageDx?.node) && (
        <>
          {lowerBand?.content?.imageDx?.node && (
            <div className="col-12 d-xl-none d-flex justify-content-center align-items-center my-2 pb-4">
              <GatsbyImage image={getImage(lowerBand.content.imageDx.node)} alt="Engie" />
            </div>
          )}
          <div className="landing-header-comparatore__bottom mb-0 p-3">
            <div className="wrapper-container row d-flex align-items-center">
              {lowerBand?.content?.imageSx?.node && (
                <div className="col-4 col-xl-1 d-flex justify-content-center align-items-center p-0">
                  <GatsbyImage
                    image={getImage(lowerBand.content.imageSx.node)}
                    alt="Engie"
                    imgStyle={{ height: "100px" }}
                  />
                </div>
              )}
              {lowerBand?.content?.lowerText?.value && (
                <div
                  className="col-8 col-xl-4 d-flex align-items-center landing-header-comparatore__bottom-text"
                  dangerouslySetInnerHTML={{ __html: lowerBand.content.lowerText.value }}
                />
              )}

              {!lowerBand?.content?.imageSx?.node && (
                <div className="col-4 col-xl-1 d-flex justify-content-center align-items-center p-0"></div>
              )}

              {lowerBand?.content?.imageDx?.node && (
                <div className="col-2 d-none d-xl-flex justify-content-center align-items-center px-0 badge">
                  <GatsbyImage image={getImage(lowerBand.content.imageDx.node)} alt="Engie" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {note?.value && (
        <div className="wrapper-container">
          <div
            className="landing-header-comparatore__note container-fluid"
            dangerouslySetInnerHTML={{ __html: note.value }}
          />
        </div>
      )}
      {scrollPosition > 0 &&
        stickyfooterText?.value &&
        offertaTitle?.[chosenProd]?.value &&
        offertaTitle?.[chosenProd]?.content?.switchTitle1?.content?.switchTitle1Footer?.value && (
          <div className="container-fluid px-0">
            <div className="sticky-footer">
              <div className="landing-header-comparatore__sticky-footer-content">
                <div className="d-none d-md-flex col-xl-5 col-6 p-0 text-nowrap">
                  <p className="sticky-footer__intro px-1">{stickyfooterText.value}</p>
                  <p className="sticky-footer__title px-lg-1">
                    {/* Se offerta configurabile mette in stickybar il nome dell'offerta presente su header */}
                    {offertaTitle[0]?.content?.isConfigurabile?.value ? (
                      <p
                        className="sticky-footer__title px-lg-1"
                        dangerouslySetInnerHTML={{ __html: mainTitle.value }}
                      ></p>
                    ) : (
                      offertaTitle[chosenProd].value
                    )}
                  </p>
                  <p className="sticky-footer__descr d-none d-lg-block px-lg-1">
                    {commodity[selectedCard].value}
                  </p>
                  <p className="sticky-footer__descr d-none d-lg-block px-lg-1">
                    {selectActive
                      ? offertaTitle[chosenProd].content.switchTitle1.content.switchTitle1Footer
                          .value
                      : offertaTitle[chosenProd].content.switchPresent.content.switchTitle2.content
                          .switchTitle2Footer.value}
                  </p>
                </div>
                <div className="d-none d-xl-flex col-3 p-0">
                  {/*Caratteristica esclusiva commodity*/}
                  {commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]
                    ?.value && (
                    <div className="sticky-footer__caratt">
                      <div className="sticky-footer__price-main">
                        {
                          commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]
                            ?.value
                        }
                      </div>
                      <div className="sticky-footer__price-descr">
                        {
                          commodity?.[selectedCard].content.caratteristicaEsclusiva?.[chosenProd]
                            ?.content?.valoreCaratteristicaEsclusiva?.value
                        }
                      </div>
                    </div>
                  )}
                  {selectActive
                    ? [
                        ...offertaTitle[chosenProd].content.switchTitle1.content.caratt1Ele?.map(
                          (caratt, j) =>
                            caratt.content?.val1FooterEle?.value &&
                            caratt?.value &&
                            (commodity?.[selectedCard].content.tipoCommodity?.value?.[0] ===
                              "dual" ||
                              commodity?.length <= 1) && (
                              <div className="sticky-footer__caratt" key={j}>
                                <div
                                  className="sticky-footer__price-main"
                                  dangerouslySetInnerHTML={{ __html: caratt.value }}
                                />
                                <div className="sticky-footer__price-descr">
                                  {caratt.content.val1FooterEle.value}
                                </div>
                              </div>
                            )
                        ),
                        ...offertaTitle[chosenProd].content.switchTitle1.content.caratt1Gas?.map(
                          (caratt, j) =>
                            caratt.content?.val1FooterGas?.value &&
                            caratt?.value &&
                            (commodity?.[selectedCard].content.tipoCommodity?.value?.[0] ===
                              "dual" ||
                              commodity?.length <= 1) && (
                              <div className="sticky-footer__caratt" key={j}>
                                <div
                                  className="sticky-footer__price-main"
                                  dangerouslySetInnerHTML={{ __html: caratt.value }}
                                />
                                <div className="sticky-footer__price-descr">
                                  {caratt.content.val1FooterGas.value}
                                </div>
                              </div>
                            )
                        ),
                      ]
                    : [
                        ...offertaTitle[
                          chosenProd
                        ].content.switchPresent.content.switchTitle2.content.caratt2Ele?.map(
                          (caratt, j) =>
                            caratt.content?.val2FooterEle?.value &&
                            caratt?.value &&
                            (commodity?.[selectedCard].content.tipoCommodity?.value?.[0] ===
                              "dual" ||
                              commodity?.length <= 1) && (
                              <div className="sticky-footer__caratt" key={j}>
                                <div
                                  className="sticky-footer__price-main"
                                  dangerouslySetInnerHTML={{ __html: caratt.value }}
                                />
                                <div className="sticky-footer__price-descr">
                                  {caratt.content.val2FooterEle.value}
                                </div>
                              </div>
                            )
                        ),
                        ...offertaTitle[
                          chosenProd
                        ].content.switchPresent.content.switchTitle2.content.caratt2Gas?.map(
                          (caratt, j) =>
                            caratt.content?.val2FooterGas?.value &&
                            caratt?.value &&
                            (commodity?.[selectedCard].content.tipoCommodity?.value?.[0] ===
                              "dual" ||
                              commodity?.length <= 1) && (
                              <div className="sticky-footer__caratt" key={j}>
                                <div
                                  className="sticky-footer__price-main"
                                  dangerouslySetInnerHTML={{ __html: caratt.value }}
                                />
                                <div className="sticky-footer__price-descr">
                                  {caratt.content.val2FooterGas.value}
                                </div>
                              </div>
                            )
                        ),
                      ]}
                </div>
                {ctaNew && (
                  <div className="d-flex flex-column flex-md-row text-center">
                    <div className="d-flex flex-column flex-md-row text-center">
                      {ctaAttivaOra.length === 1 && (
                        <button
                          title={ctaAttivaOra[0].value}
                          className={classNames(
                            "cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 " +
                              ctaAttivaOra[0].content?.tipoCtaNew?.value
                          )}
                          onClick={() =>
                            salable
                              ? startFlusso(
                                  offertaTitle?.[chosenProd]?.content?.offertaCtaLink?.value,
                                  false
                                )
                              : cortesia(
                                  offertaTitle?.[chosenProd]?.content?.offertaCtaLink?.value,
                                  false
                                )
                          }
                        >
                          {ctaAttivaOra[0].value}
                        </button>
                      )}
                    </div>
                    <div className="d-flex flex-column flex-md-row text-center px-0 px-lg-2">
                      {ctaSimualtore.length === 1 && (
                        <button
                          title={ctaSimualtore[0]?.value}
                          onClick={() => {
                            Cookies.set(
                              "simulatoreNomeProdotto",
                              ctaSimualtore[0].content.nameProductSimulatore.value
                            );
                            window.open("/simulatore-bolletta");
                          }}
                          className={classNames(
                            "cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 " +
                              ctaSimualtore[0].content?.tipoCtaNew?.value
                          )}
                        >
                          {ctaSimualtore[0]?.value}
                        </button>
                      )}
                      {ctaChiamaOra.length === 1 && (
                        <Link
                          to={ctaChiamaOra[0]?.content?.linkCtaNew?.value}
                          title={ctaChiamaOra[0]?.value}
                          className={classNames(
                            "cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 " +
                              ctaChiamaOra[0].content?.tipoCtaNew?.value,
                            ctaChiamaOra[0]?.content?.linkCtaNew?.value.startsWith("tel") &&
                              "d-block d-xl-none"
                          )}
                        >
                          {ctaChiamaOra[0]?.value}
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </section>
  );
};

export default withPreview(LandingHeaderComparatore);
export const fragment = graphql`
  fragment LandingHeaderComparatoreFragment on LiferayLandingHeaderComparatore {
    liferayFields {
      siteId
      articleId
    }
    mainTitle {
      value
      content {
        mainDescription {
          value
        }
        cdownDate {
          value
        }
        showProductStars {
          value
          content {
            dataProductId {
              value
            }
          }
        }
      }
    }
    imageDesktop {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageTablet {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageMobile {
      node {
        gatsbyImageData(layout: FULL_WIDTH)
        probeInfo {
          aspectRatio
        }
      }
    }
    cta {
      value
      content {
        ctaColor {
          value
        }
        ctaLink {
          value
        }
        ctaSubtitle {
          value
        }
      }
    }
    ctaNew {
      content {
        coloreCtaNew {
          value
        }
        coloreCtaNewClick {
          value
        }
        coloreCtaNewHover {
          value
        }
        linkCtaNew {
          value
        }
        nameProductSimulatore {
          value
        }
        ctaNewSubtitle {
          value
        }
        tipoCtaNew {
          value
        }
      }
      value
    }
    aboveCardTitle {
      value
    }
    commodity {
      value
      content {
        tipoCommodity {
          value
        }
        caratteristicaEsclusiva {
          content {
            valoreCaratteristicaEsclusiva {
              value
            }
            tooltipCaratteristicaEclusiva {
              value
            }
          }
          value
        }
      }
    }
    offertaTitle {
      value
      content {
        prodottoCRM {
          dataFineValidita
          corrispettivoEnergia
          corrispettivoF1
          corrispettivoF2
          corrispettivoF3
          componentePrezzoFisso
          spreadF1
          spreadF2
          spreadF3
          corrispettivoBiorariaF1
          corrispettivoBiorariaF23
          quotaCommFissaDomesticoGas
          quotaCommFissaDomesticoLuce
          quotaCommVariabileDomesticoGas
          quotaCommVariabileDomesticoLuce
          quotaPvolSmc
          quotaPvolkWh
          quotaPvolF1kWh
          quotaPvolF2kWh
          quotaPvolF3kWh
          quotaAlfakWh
          quotaAlfaF1kWh
          quotaAlfaF2kWh
          quotaAlfaF3kWh
          spreadGas
          spreadLuce
          spreadLuceLordoPerdite
        }
        offertaCtaLink {
          value
        }
        isConfigurabile {
          value
        }
        offertaLabel {
          value
          content {
            offertaLabelColor {
              value
            }
          }
        }
        offertaIcona {
          node {
            gatsbyImageData(formats: PNG, width: 215)
          }
        }
        offertaSwitchButtonTitle {
          value
          content {
            coloreBordoBottone {
              value
            }
            coloreBottone1 {
              value
            }
            coloreBottone2 {
              value
            }
          }
        }

        switchTitle1 {
          value
          content {
            switchTitle1Footer {
              value
            }
            firstTooltip {
              value
            }
            caratt1Ele {
              value
              content {
                valueField1Ele {
                  value
                }
                isBold1Ele {
                  value
                }
                val1FooterEle {
                  value
                }
                isTooltip1Ele {
                  value
                  content {
                    carattTooltipSw1Ele {
                      value
                    }
                  }
                }
                hrSw1Ele {
                  value
                }
              }
            }
            caratt1Gas {
              value
              content {
                valueField1Gas {
                  value
                }
                isBold1Gas {
                  value
                }
                val1FooterGas {
                  value
                }
                isTooltip1Gas {
                  value
                  content {
                    carattTooltipSw1Gas {
                      value
                    }
                  }
                }
                hrSw1Gas {
                  value
                }
              }
            }
          }
        }
        switchPresent {
          value
          content {
            switchTitle2 {
              value
              content {
                caratt2Ele {
                  value
                  content {
                    valueField2Ele {
                      value
                    }
                    hrSw2Ele {
                      value
                    }
                    isBold2Ele {
                      value
                    }
                    isTooltip2Ele {
                      content {
                        carattTooltipSw2Ele {
                          value
                        }
                      }
                      value
                    }
                    val2FooterEle {
                      value
                    }
                  }
                }
                caratt2Gas {
                  value
                  content {
                    valueField2Gas {
                      value
                    }
                    hrSw2Gas {
                      value
                    }
                    isBold2Gas {
                      value
                    }
                    isTooltip2Gas {
                      content {
                        carattTooltipSw2Gas {
                          value
                        }
                      }
                      value
                    }
                    val2FooterGas {
                      value
                    }
                  }
                }
                secondTooltip {
                  value
                }
                switchTitle2Footer {
                  value
                }
              }
            }
          }
        }
      }
    }
    lowerBand {
      content {
        imageSx {
          node {
            gatsbyImageData(width: 100)
          }
        }
        lowerText {
          value
        }
        imageDx {
          node {
            gatsbyImageData(width: 245)
          }
        }
      }
    }
    stickyfooterText {
      value
    }
    note {
      value
    }
  }
`;
